@font-face {
  font-family: Yu Gothic;
  src: url("/fonts/yugothil.ttf");
  format: ("OpenType");
  font-display: swap;
}

@font-face {
  font-family: SimSun;
  src: url("/fonts/SIMSUN.ttf");
  format: ("OpenType");
  font-display: swap;
}

/* needed for the side scrollbar to work correctly */
body {
  height: 100%;
}
html {
  height: 100%;
  scroll-behavior: smooth;
}
